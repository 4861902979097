import { default as abuseReportsGdioSSsn8oMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93LRCIEY0xedMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index3DQigYnn3zMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/index.vue?macro=true";
import { default as moderationc2gHUkBH0LMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/moderation.vue?macro=true";
import { default as pendingBansr6cC4SyzbHMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/pendingBans.vue?macro=true";
import { default as profilesYl4q6YlUpGMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/profiles.vue?macro=true";
import { default as expensesWI1xe8yT5QMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexJfX3r600I8Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewjA16FuYjKRMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingd6uWLJLb61Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/translations/awaiting.vue?macro=true";
import { default as missinguIrQEgSCIVMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/translations/missing.vue?macro=true";
import { default as userszYnKH8jiO0Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/admin/users.vue?macro=true";
import { default as api77JPvnHMwMMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/api.vue?macro=true";
import { default as _91slug_93qUqByD9b2oMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/blog/[slug].vue?macro=true";
import { default as indexLzNhLVrHLSMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_9340XQmCGvKSMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_936t3WHDVBy4Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminZCdwwVp5E1Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/census/admin.vue?macro=true";
import { default as indexqMl9e23fygMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/census/index.vue?macro=true";
import { default as contact6X4fLSekIqMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/contact.vue?macro=true";
import { default as designC7qNjhh7xRMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/design.vue?macro=true";
import { default as englishShJrQoys5VMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/english.vue?macro=true";
import { default as faq2SUxI4kyuTMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/faq.vue?macro=true";
import { default as inclusivempZEovVQYcMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/inclusive.vue?macro=true";
import { default as index3pSe16z38RMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/index.vue?macro=true";
import { default as licenseR0E7VohRogMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/license.vue?macro=true";
import { default as academicKPI3uOda4TMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/links/academic.vue?macro=true";
import { default as indexUJgLCPNy3FMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/links/index.vue?macro=true";
import { default as mediaa0yUMDxyQZMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/links/media.vue?macro=true";
import { default as translinguisticsb8dd4ory4oMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/links/translinguistics.vue?macro=true";
import { default as zinedcp4zun39bMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/links/zine.vue?macro=true";
import { default as namesmOYabX6b1WMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/names.vue?macro=true";
import { default as index050eavalnSMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/nouns/index.vue?macro=true";
import { default as templatesh7dCyfLSPTMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/nouns/templates.vue?macro=true";
import { default as peopleZ1hkcW0hsqMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/people.vue?macro=true";
import { default as privacyN0XmV7KMuwMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/privacy.vue?macro=true";
import { default as _91username_93GRlN1QbDWoMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93HaYl2A7vuGMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/profile/card-[username].vue?macro=true";
import { default as editor5O26oGJlCOMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/profile/editor.vue?macro=true";
import { default as anyPVRkTuvSTHMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/any.vue?macro=true";
import { default as ask0RZBMCN7HxMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/ask.vue?macro=true";
import { default as avoidingD6dJ0PsChnMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/avoiding.vue?macro=true";
import { default as index5yE238m7ozMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/index.vue?macro=true";
import { default as mirrorisOLs4b9btMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/mirror.vue?macro=true";
import { default as pronounxn9j6MXDT6Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/pronoun.vue?macro=true";
import { default as sourceskQnk0fWXXtMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/sources.vue?macro=true";
import { default as teamNex4EVzesQMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/team.vue?macro=true";
import { default as terminologyCYthcPqPOPMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/terminology.vue?macro=true";
import { default as termsASH9GYaZr0Meta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/terms.vue?macro=true";
import { default as userybiC0D2mexMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/user.vue?macro=true";
import { default as workshopsyMXiV1OqfJMeta } from "/home/admin/www/pronouns.page/release/20241111213703/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsGdioSSsn8oMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansr6cC4SyzbHMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: api77JPvnHMwMMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93qUqByD9b2oMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexLzNhLVrHLSMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_9340XQmCGvKSMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_936t3WHDVBy4Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminZCdwwVp5E1Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexqMl9e23fygMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact6X4fLSekIqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishShJrQoys5VMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq2SUxI4kyuTMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivempZEovVQYcMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicKPI3uOda4TMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexUJgLCPNy3FMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaa0yUMDxyQZMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsb8dd4ory4oMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinedcp4zun39bMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesmOYabX6b1WMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index050eavalnSMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesh7dCyfLSPTMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleZ1hkcW0hsqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyN0XmV7KMuwMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93GRlN1QbDWoMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93HaYl2A7vuGMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor5O26oGJlCOMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyPVRkTuvSTHMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: ask0RZBMCN7HxMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingD6dJ0PsChnMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index5yE238m7ozMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorisOLs4b9btMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounxn9j6MXDT6Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourceskQnk0fWXXtMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamNex4EVzesQMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyCYthcPqPOPMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsASH9GYaZr0Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userybiC0D2mexMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsyMXiV1OqfJMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241111213703/pages/workshops.vue")
  }
]